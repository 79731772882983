












import Vue from "vue";
import NavBar from "./components/NavBar.vue";

export default Vue.extend({
  name: "App",
  created() {
    this.axios.get('auth/user')
      .then((res) => {
        const info = res.data;
        this.$store.state.authInfo.id = info.id;
        this.$store.state.authInfo.email = info.email;
        this.$store.state.authInfo.preName = info.preName;
        this.$store.state.authInfo.lastName = info.lastName;
        this.$store.state.authInfo.permissions = info.permissions;
        this.$store.state.authInfo.totpEnabled = info.totpEnabled;
        this.$store.state.loggedIn = true;
        const redirect = this.$router.currentRoute.query.redirect.toString();
        if (redirect) this.$router.push(redirect);
      });
  },
  data: () => ({
    drawer: true,
    mini: true,
    privacy: false,
  }),
  components: {
    NavBar,
  },
});
