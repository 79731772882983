import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'

const apiUrls: Record<string, string> = {
  local: 'http://localhost:3000/',
  dev: 'https://api-dev.itmr.dev/',
  prod: 'https://api.itmr.dev/',
};

Vue.use(VueAxios, axios)
axios.defaults.baseURL = apiUrls[process.env.VUE_APP_API_URL || 'dev'];
axios.defaults.withCredentials = true;

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    /*
    toast: function (title, text, variant = null) {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
        autoHideDelay: 2000,
        toaster: 'b-toaster-top-right',
        appendToast: false
      })
    },
    */
    logout() {
      store.state.loggedIn = false;
      store.state.authInfo = {
        id: null,
        email: null,
        preName: null,
        lastName: null,
        permissions: null,
        totpEnabled: null,
      };
      axios.post('auth/logout');
      router.push('/login');
    },
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.authInfo.id === null) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
      // this.toast('Es ist ein Fehler aufgetreten', 'Du musst dich zuerst anmelden!', 'danger')
      store.state.loggedIn = false;
    } else {
      next();
      store.state.loggedIn = true;
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
